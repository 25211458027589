import axios from "axios";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Link, Outlet, useLocation } from "react-router-dom";

const Blog = () => {



  return (
      <div className="bg-[#F3F5F9] py-20">
        <section className=" mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
          <div className="text-center space-y-4">
            <h1 className="text-gray-800 font-bold text-4xl md:text-5xl">
              SproutsAi {"  "}
              <span className="text-indigo-600">Blog</span>
            </h1>
          </div>
        </section>

        <Outlet />

      </div>
  );
};

export default Blog;
