import axios from "axios";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

function BlogList() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://public-api.wordpress.com/wp/v2/sites/akriti91064f85eb.wordpress.com/posts"
      )
      .then((response) => setBlogs(response.data))
      .catch((error) => console.error("Error fetching blogs:", error));
  }, []);
  return (
    <section className=" mx-auto max-w-screen-xl pb-4 px-4 sm:px-8 mt-8">
      <div className=" space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs?.map((post) => (
            <div className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-300"
            key={post.id}
            >
              <a href={`/post?id=${post.id}`}>
                <div className="relative h-48 sm:h-56">
                  <img
                    src={post?.jetpack_featured_media_url || "/placeholder.svg"}
                    alt=""
                    fill
                    height={200}
                    className="w-full h-48 object-cover"
                    priority
                  />
                </div>
                <div className="p-6">
                  <h2 className="text-2xl font-bold text-gray-900 mb-2 line-clamp-2">
                    {post?.title?.rendered}
                  </h2>
                  <p className="text-gray-500 text-sm mb-3">
                    {new Date(post.date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                  <p className="text-gray-600 mb-4 line-clamp-3">
                    {" "}
                    {parse(
                      post?.excerpt?.rendered.length > 80
                        ? post?.excerpt?.rendered.substring(0, 80) + "..."
                        : post?.excerpt?.rendered
                    )}
                  </p>
                  <span className="text-blue-600 font-medium hover:text-blue-700">
                    Read More
                  </span>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default BlogList;
