import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";

function Posts() {
  const location = useLocation();
  const id = Number(new URLSearchParams(location.search).get("id")); // Extract ID properly

  const [blogs, setBlogs] = useState(null);
  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://public-api.wordpress.com/wp/v2/sites/akriti91064f85eb.wordpress.com/posts`
      )
      .then((response) => {
        const allBlogs = response.data ?? [];

        // Find the currently viewed blog
        const currentBlog = allBlogs.find((blog) => blog?.id === id) || null;
        setBlogs(currentBlog);

        // Exclude the current blog and get only two other blogs
        const filteredBlogs = allBlogs.filter((blog) => blog?.id !== id).slice(0, 2);
        setBlogList(filteredBlogs);
      })
      .catch((error) => console.error("Error fetching blogs:", error));
  }, [id]);

  return (
    <section className="py-10 bg-[#F3F5F9]">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          
          {/* Blog Content */}
          <div className={`bg-white rounded-lg shadow-md p-6 ${blogList.length === 0 ? "md:col-span-3 flex justify-center" : "md:col-span-2"}`}>
            {blogs ? (
              <div className="prose-lg prose" dangerouslySetInnerHTML={{ __html: blogs.content.rendered }} />
            ) : (
              <div className="h-40 flex items-center justify-center">Loading...</div>
            )}
          </div>

          {/* Sidebar - Show Two Other Blog Posts */}
          {blogList.length > 0 && (
            <div className="flex flex-col gap-4">
              {blogList.map((post) => (
                <div
                  className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-300"
                  key={post.id}
                >
                  <a href={`/post?id=${post.id}`}>
                    <div className="relative w-full h-48">
                      <img
                        src={post.jetpack_featured_media_url || "/placeholder.svg"}
                        alt={post.title.rendered}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="p-5">
                      <h2 className="text-xl font-bold text-gray-900 mb-2 line-clamp-2">
                        {post.title.rendered}
                      </h2>
                      <p className="text-gray-500 text-sm mb-3">
                        {new Date(post.date).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </p>
                      <p className="text-gray-600 mb-4 line-clamp-3">
                        {parse(
                          post.excerpt.rendered.length > 80
                            ? post.excerpt.rendered.substring(0, 80) + "..."
                            : post.excerpt.rendered
                        )}
                      </p>
                      <span className="text-blue-600 font-medium hover:text-blue-700">
                        Read More
                      </span>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Posts;
